import React, { useState, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import MaterialTable from 'material-table';
import { localization } from '../../../config';
import BoiteOrForm from '../form/BoiteOrForm';
import { ajouterBoiteOr } from '../action/AtelierAction';
import BoiteOr from '../analyse/BoiteOr';
import { Typography } from '@material-ui/core';

const EnvoiBoiteOr = () => {
    const [data, setData] = useState([]);

    const dataRef = useRef(data);

    useEffect(() => {
        dataRef.current = data;
    }, [data]);

    useEffect(() => {
        return () => {
            if (dataRef.current.length > 0) {
                let confirmation = window.confirm("Enregistrer avant de quitter ?");
                if (confirmation) {
                    save();
                }
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleAjouter = (formData) => {
        const { joaillier, poidsD, poidsR } = formData;
        const newData = {
            joaillier: joaillier.label,
            id_joaillier: joaillier.id,
            poidsD,
            poidsR,
        };
        setData([newData, ...data]);
    };

    const handleSupprimer = (index) => {
        const newData = [...data];
        newData.splice(index, 1);
        setData(newData);
    };

    const save = () => {
        ajouterBoiteOr(data)
            .then(() => {
                setData([]);
            })
            .catch(error => {
                console.error("Erreur lors de l'ajout :", error);
            });
    };
    return (
        <div>
            <h1 style={{ textAlign: 'center' }}>Boite OR</h1>
            <div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="col-md-6">
                    <BoiteOr />
                </div>
                <div className="col-md-6 mx-auto">
                    <Typography variant="h6" align='center'>Ajouter Boite Or</Typography>
                    <div className="col-md-3 mx-auto">
                        <BoiteOrForm onSubmitForm={handleAjouter} />
                    </div>
                    <div className="col-md-12" style={{ textAlign: 'center', marginTop: '20px' }}>
                        <MaterialTable
                            title=""
                            options={{
                                search: false,
                            }}
                            columns={[
                                { title: 'Joaillier', field: 'joaillier', align: 'center' },
                                { title: 'Poids Donne', field: 'poidsD' },
                                { title: 'Poids Rendu', field: 'poidsR' },
                                {
                                    title: '',
                                    field: 'action',
                                    render: rowData => (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={(e) => { e.stopPropagation(); handleSupprimer(data.indexOf(rowData)) }
                                            }>
                                            Supprimer
                                        </Button>
                                    ),
                                    align: 'center'
                                }
                            ]}
                            data={data}
                            localization={localization}
                        />
                        <div style={{ textAlign: 'center', marginTop: '20px' }}>
                            <Button variant="contained" color="primary" onClick={save}>Enregistrer</Button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};


export default EnvoiBoiteOr;
