import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Formik, useFormik } from 'formik'; // 1. Importation de Form
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import { getListeOf, getListeSertisseur } from '../../../actions';
import { getOfs } from '../../../selectors/ofsSelector';

const validationSchema = Yup.object().shape({
    of: Yup.object().required('N° OF obligatoire.'),
    sertisseur: Yup.object().required('Sertisseur obligatoire.'),
    poids: Yup.number()
        .required('Poids valide obligatoire.')
        .moreThan(-1, 'Le poids rendu doit être un nombre positif.'),
    poidsR: Yup.number()
        .required('Poids Rendu valide obligatoire.')
        .positive('Le poids rendu doit être un nombre positif.'),
    prix: Yup.number().required('Prix valide obligatoire.').moreThan(-1, 'Le poids rendu doit être un nombre positif.'),
});

const SertisseurForm = ({ listeSertisseurs, getListeSertisseur, listeOfs, getListeOf, onSubmitForm, dataForm }) => {
    const ofRef = useRef(null);
    const sertisseurRef = useRef(null);
    const poidsRef = useRef(null);
    const poidsRRef = useRef(null);
    const prixRef = useRef(null);

    const formik = useFormik({
        initialValues: {
            of: null,
            sertisseur: null,
            poids: '',
            poidsR: '',
            prix: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            onSubmitForm(values);
            resetForm();
        },
    });
    const { values, errors, touched, handleChange, handleSubmit, handleBlur, setFieldValue } = formik;

    useEffect(() => {
        getListeSertisseur();
        getListeOf();
    }, [getListeSertisseur, getListeOf]);
    useEffect(() => {
        if (dataForm !== null) {
            populateForm(dataForm);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm]);


    const populateForm = (data) => {
        const selectedOf = listeOfs.find(of => of.num_sachet === data.of);
        setFieldValue('of', selectedOf);
        if (!data.of) ofRef.current.focus();
    };


    useEffect(() => {
        if (values.of && sertisseurRef.current) {
            sertisseurRef.current.focus();
        }
    }, [values.of]);
    useEffect(() => {
        if (values.sertisseur && poidsRef.current) {
            poidsRef.current.focus();
        }
    }, [values.sertisseur]);

    const handleKeyPress = async (event, nextRef) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            // Persiste l'événement
            event.persist();
            // Valider le champ actuel
            await formik.validateField(event.target.name);
            // Accéder à l'erreur
            const error = formik.errors[event.target.name];
            formik.setFieldTouched(event.target.name)
            // Si le champ actuel est valide
            console.log(error);
            if (!error) {
                nextRef.current.focus();
            }
        }
    };


    return (
        <Formik initialValues={values} onSubmit={handleSubmit} >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Autocomplete
                        options={listeOfs}
                        getOptionLabel={(option) => option.num_sachet}
                        value={values.of}
                        onChange={(event, newValue) => setFieldValue('of', newValue)}
                        autoHighlight
                        autoComplete
                        fullWidth
                        clearOnEscape
                        getOptionSelected={(option) => option.num_sachet === values.of.num_sachet}
                        renderInput={(params) => <TextField {...params} label="N° OF" variant="outlined" fullWidth
                            inputRef={ofRef}
                            error={errors.of && touched.of}
                            helperText={(errors.of && touched.of) ? errors.of : null} />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        options={listeSertisseurs.filter(r => r.actif === 1)}
                        getOptionLabel={(option) => option.label}
                        value={values.sertisseur}
                        onChange={(event, newValue) => setFieldValue('sertisseur', newValue)}
                        autoHighlight
                        autoComplete
                        fullWidth
                        clearOnEscape
                        renderInput={(params) => <TextField {...params} label="Sertisseur" variant="outlined" fullWidth
                            inputRef={sertisseurRef}
                            autoFocus
                            error={errors.sertisseur && touched.sertisseur}
                            helperText={(errors.sertisseur && touched.sertisseur) ? errors.sertisseur : null} />}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Poids"
                        variant="outlined"
                        value={values.poids}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="poids"
                        error={errors.poids && touched.poids}
                        helperText={(errors.poids && touched.poids) ? errors.poids : null}
                        inputRef={poidsRef}
                        onKeyPress={(e) => handleKeyPress(e, poidsRRef)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Poids Rendu"
                        variant="outlined"
                        value={values.poidsR}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="poidsR"
                        error={errors.poidsR && touched.poidsR}
                        helperText={(errors.poidsR && touched.poidsR) ? errors.poidsR : null}
                        inputRef={poidsRRef}
                        onKeyPress={(e) => handleKeyPress(e, prixRef)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Prix"
                        variant="outlined"
                        value={values.prix}
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="prix"
                        error={errors.prix && touched.prix}
                        helperText={(errors.prix && touched.prix) ? errors.prix : null}
                        inputRef={prixRef}
                        onKeyPress={async (e) => {
                            if (e.key === 'Enter') {
                                const errors = await formik.validateForm();
                                formik.setFieldTouched('prix')
                                console.log(errors);
                                if (!errors.prix) {
                                    formik.handleSubmit();
                                    ofRef.current.focus();
                                }
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" color="primary" type="submit" onClick={handleSubmit}>Ajouter</Button>
                </Grid>
            </Grid>
        </Formik>
    );
};
const mapStateToProps = (state) => {
    return {
        listeSertisseurs: state.generalData.listeSertisseur,
        listeOfs: getOfs(state),
    };
};

const mapDispatchToProps = {
    getListeSertisseur,
    getListeOf,

};
export default connect(mapStateToProps, mapDispatchToProps)(SertisseurForm); 