import Axios from 'axios';
import React, { Component } from 'react'
import { imagesRepo } from '../config'
import imageCompression from 'browser-image-compression';
import { roundPrecision2 } from '../helpers/functions'
import { TextField } from '@material-ui/core';

class ImageLoader extends Component {
    state = {
        files: [],
    };

    resizeImage = async (imageFile, size) => {
        // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
        // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: size,
            useWebWorker: true
        }
        try {
            const resized = await imageCompression(imageFile, options);
            return resized

        } catch (error) {
            console.log(error);
        }
    }

    handleImageUpload = async (event) => {
        if (!event.target.files) return
        const imageFile = event.target.files
        Object.values(imageFile).forEach(async (img) => {
            try {
                const resized = await this.resizeImage(img, 1000)
                //const resizedThumb = await this.resizeImage(img, 250)
                const tab = {
                    initialZize: img.size,
                    image: resized,
                    // thumb: resizedThumb,
                }
                this.setState({ files: [...this.state.files, tab] })
            } catch (error) {
                console.log(error);
            }
        })
    }
    removeFile = (file) => {
        let tab = Object.values(this.state.files).filter(img => img.image.name !== file.image.name)
        console.log(tab);
        this.setState({ files: tab })
    }
    rename = (oldName, newName) => {
        let tab = Object.values(this.state.files).map(img => {
            if (img.image.name === oldName) {
                img.image.name = newName
                //img.thumb.name = newName
            }
            return img
        })
        this.setState({ files: tab })
    }
    // On file upload (click the upload button)
    onFileUpload = () => {

        Object.values(this.state.files).forEach(async (f) => {
            const formData = new FormData();
            formData.append(
                "image",
                f.image,
                f.image.name
            );
            await Axios.post(`${imagesRepo}upload.php`, formData);
            // const formDataThumb = new FormData();
            // formDataThumb.append(
            //     "image",
            //     f.thumb,
            //     f.thumb.name
            // );
            // await Axios.post(`${imagesRepo}.thumbs/uploadThumb.php`, formDataThumb);
            this.removeFile(f)
        })
    }

    renderImage = (file) => {
        return (
            <div className="col-auto " key={file.image.name}>
                <div >
                    <div className='d-flex justify-content-center'>
                        <img style={{ maxWidth: 300, maxHeight: 300, minWidth: 300, minHeight: 300 }} src={URL.createObjectURL(file.image)} alt='' />
                    </div>
                    <h3 className='text-center'> {file.image.name}</h3>
                    <TextField inputProps={{ style: { textAlign: 'center' } }}
                        fullWidth variant='outlined' autoFocus defaultValue={file.image.name}

                        onBlur={e => this.rename(file.image.name, e.target.value)} />
                    <p className='text-center'> Taille Initiale:{roundPrecision2(file.initialZize / 1024 / 1024)} Mo</p>
                    <p className='text-center'> Taille Compressée:{roundPrecision2(file.image.size / 1024 / 1024)} Mo</p>
                    <p className='text-center'> Gain Compression:{roundPrecision2(1 - file.image.size / file.initialZize) * 100} %</p>
                    {/* <p className='text-center'>Type: {file.image.type}</p> */}
                    <div className='d-flex justify-content-center'>
                        <button onClick={() => this.removeFile(file)} className="btn btn-link btn-sm">Supprimer</button>
                    </div>
                </div>

            </div>
        )
    }

    render() {

        return (
            <div>
                <h3>
                    {this.state.files.length !== 0 ? 'Uploader ' + this.state.files.length + ' Image(s)' : 'Uploader une Image '}

                </h3>

                <div className='form-group'>
                    <input style={{ display: 'none' }} ref={fileInput => this.fileInput = fileInput} type="file" multiple accept="image/*" onLoadedData={() => console.log('ok')} onChange={this.handleImageUpload} />
                    <button onClick={() => this.fileInput.click()} style={{ color: '#007bff' }} className='btn btn-primary btn-lg'>Importer Image(s)</button>

                    {this.state.files.length !== 0 && <button onClick={this.onFileUpload} style={{ color: '#007bff' }} className='btn btn-primary btn-lg'>
                        Uploader!
                    </button>}
                </div>

                {this.state.files.length !== 0 && <div className="row">
                    {
                        Object.values(this.state.files).map(file => {
                            return this.renderImage(file)
                        })
                    }
                </div>}
            </div>
        );
    }
}


export default (ImageLoader)