import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios';
import { getTypeProduit, getMetaux } from '../../actions';
import { meekroDB } from '../../config';
import TreeClient from '../../components/treeClient';
import FilterSelect from '../../components/filterSelect';
import FilterInput from '../../components/filterInput';
import { Doughnut } from 'react-chartjs-2';
import { euroFormat } from '../../helpers/functions';
import PopupTable from '../../components/PopupTable';

class AnalyseStock extends Component {
    state = {
        ref: '',
        of: '',
        listeOf: '',
        plateau: '',
        client: '',
        type: '',
        metal: '',
        listeStockProduit: [],
        nombreTotal: 0,
        totalPrixRevient: 0,
        columnsData: null,
        title: null,
        data: null,
        popupOpen: false,
    }

    componentDidMount() {
        this.props.getTypeProduit();
        this.props.getMetaux();
    }

    onChange = (data, labelState) => {
        const label = labelState.toLowerCase();
        this.setState({ [label]: data });
    }
    getFilter = () => {
        const { client, ref, metal, type, plateau } = this.state;
        return { agences: client, ref, metal, type, plateau };
    }
    afficher = () => {


        Axios.post(`${meekroDB}/analyse/analyse_stock.php?action=analyse_stock`, this.getFilter())
            .then((response) => {
                const stockProduit = response.data;
                let nombreTotal = 0;
                let totalPrixRevient = 0;

                // Calculer nombreTotal et totalPrixRevient
                stockProduit.forEach(produit => {
                    nombreTotal += parseInt(produit.nombre);
                    totalPrixRevient += parseFloat(produit.total_revient);
                });

                // Mettre à jour le state avec les nouvelles valeurs calculées
                this.setState({
                    listeStockProduit: stockProduit,
                    nombreTotal: nombreTotal,
                    totalPrixRevient: totalPrixRevient
                });
            })
            .catch(error => {
                console.log(error);
            });
    }

    handleTrancheClick = (segmentData) => {
        console.log(segmentData);
        let min, max;
        const { prix_r_range } = segmentData;
        if (prix_r_range.includes("à")) {
            const segmentParts = prix_r_range.split(" à ");
            min = segmentParts[0];
            max = segmentParts[1];
        } else if (prix_r_range.startsWith(">")) {
            min = prix_r_range.substring(1); // Supprime le caractère ">"
            max = 100000000;
        } else if (prix_r_range === "0") {
            min = 0;
            max = 0;
        }
        Axios.post(`${meekroDB}/analyse/analyse_stock.php?action=detail_stock&min=${min}&max=${max}`, this.getFilter())
            .then(response => {
                const columnsTranche = [
                    { title: 'OF', field: 'of', align: 'center', type: "numeric" },
                    { title: 'Ref', field: 'ref', align: 'center', type: "numeric" },
                    { title: 'Designation', field: 'designation', align: 'center' },
                    { title: 'Prix Revient', field: 'prix_r', type: "numeric", align: 'center', render: rowData => euroFormat(rowData.prix_r) },
                    { title: 'Lieu', field: 'Nom_agence', align: 'center' },
                ];
                this.setState({
                    columnsData: columnsTranche, title: `Détail Tranche Prix : ${segmentData.prix_r_range} €`, data: response.data, popupOpen: true
                })
            })
            .catch(error => {
                console.log(error);
            });

    };
    handleChangeClient = (data) => {
        this.setState({ client: data });
    }

    render() {
        const { listeStockProduit, nombreTotal, totalPrixRevient } = this.state;


        const doughnutData = {
            labels: listeStockProduit.map(item => item.prix_r_range),
            datasets: [{
                data: listeStockProduit.map(item => parseInt(item.nombre)),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.7)',
                    'rgba(54, 162, 235, 0.7)',
                    'rgba(255, 206, 86, 0.7)',
                    'rgba(240, 10, 10, 0.7)',
                    'rgba(120, 206, 10, 0.7)',
                ],
            }],
        };
        const doughnutDataPrixR = {
            labels: listeStockProduit.map(item => item.prix_r_range),
            datasets: [{
                data: listeStockProduit.map(item => parseInt(item.total_revient)),
                backgroundColor: [
                    'rgba(255, 99, 132, 0.7)',
                    'rgba(54, 162, 235, 0.7)',
                    'rgba(255, 206, 86, 0.7)',
                    'rgba(240, 10, 10, 0.7)',
                    'rgba(120, 206, 10, 0.7)',
                ],
            }],
        };

        const options = {

            legend: {
                display: true,
                position: 'right',
                onClick: () => { },
                labels: {
                    fontSize: 16,
                    generateLabels: function (chart) {
                        const data = chart.data;
                        if (data.labels.length && data.datasets.length) {
                            const ds = data.datasets[0];
                            const legendLabels = [];

                            const titreLegend = `Nombre de Produits: ${nombreTotal}`;
                            legendLabels.push({
                                text: titreLegend,
                                fillStyle: 'transparent',
                                lineWidth: 0,
                                index: -1 // Indice arbitraire pour placer ce label en haut
                            });
                            legendLabels.push({
                                text: "",
                                fillStyle: 'transparent',
                                lineWidth: 0,
                                index: -2 // Indice arbitraire pour placer ce label en haut
                            });


                            // Détails pour chaque segment
                            data.labels.forEach((label, i) => {
                                const item = listeStockProduit[i]; // Obtenir l'objet correspondant de listeStockProduit
                                const currentValue = parseInt(item.nombre); // Utiliser nombre pour obtenir le nombre d'éléments
                                const percentage = (currentValue / nombreTotal) * 100; // Recalculer le pourcentage en fonction de nombreTotal

                                legendLabels.push({
                                    text: `${label} (${currentValue} Produits / ${percentage.toFixed(2)}%)`,
                                    fillStyle: ds.backgroundColor[i],
                                    hidden: isNaN(ds.data[i]) || chart.getDatasetMeta(0).data[i].hidden,
                                    index: i
                                });
                            });

                            return legendLabels;
                        } else {
                            return [];
                        }
                    }
                }
            },
            tooltips: {
                enabled: true,
                callbacks: {
                    title: function (tooltipItems, data) {
                        return 'Cliquez pour voir le détail';
                    },
                    label: function (tooltipItem, data) {
                        var label = data.labels[tooltipItem.index] || '';

                        if (label) {
                            label += ': ';
                        }
                        const value = data.datasets[0].data[tooltipItem.index];
                        label += value; // Utilisez votre propre formatage des données ici si nécessaire
                        return label;
                    }
                }
            }
        };
        const optionsPrixR = {

            legend: {
                display: true,
                position: 'left',
                onClick: () => { },
                labels: {
                    fontSize: 16,
                    generateLabels: function (chart) {
                        const data = chart.data;
                        if (data.labels.length && data.datasets.length) {
                            const ds = data.datasets[0];
                            const legendLabels = [];

                            const titreLegend = `Prix de Revient: ${euroFormat(totalPrixRevient)}`;
                            legendLabels.push({
                                text: titreLegend,
                                fillStyle: 'transparent',
                                lineWidth: 0,
                                index: -1 // Indice arbitraire pour placer ce label en haut
                            });
                            legendLabels.push({
                                text: "",
                                fillStyle: 'transparent',
                                lineWidth: 0,
                                index: -2 // Indice arbitraire pour placer ce label en haut
                            });


                            // Détails pour chaque segment
                            data.labels.forEach((label, i) => {
                                const item = listeStockProduit[i]; // Obtenir l'objet correspondant de listeStockProduit
                                const prixRangeTotal = parseFloat(item.total_revient); // Utiliser total_revient pour obtenir le total
                                const prixRevientPercentage = (prixRangeTotal / totalPrixRevient) * 100; // Calculer le pourcentage du prix de revient

                                legendLabels.push({
                                    text: `${label} Total: ${euroFormat(prixRangeTotal)} (${prixRevientPercentage.toFixed(2)}%)`,
                                    fillStyle: ds.backgroundColor[i],
                                    hidden: isNaN(ds.data[i]) || chart.getDatasetMeta(0).data[i].hidden,
                                    index: i
                                });
                            });

                            return legendLabels;
                        } else {
                            return [];
                        }
                    }
                }
            },
            tooltips: {
                enabled: true,
                callbacks: {
                    title: function (tooltipItems, data) {
                        return 'Cliquez pour voir le détail';
                    },
                    label: function (tooltipItem, data) {
                        var label = data.labels[tooltipItem.index] || '';

                        if (label) {
                            label += ': ';
                        }
                        const value = data.datasets[0].data[tooltipItem.index];
                        label += euroFormat(value); // Utilisez votre propre formatage des données ici si nécessaire
                        return label;
                    }
                }
            }
        };







        const { popupOpen, columnsData, title, data } = this.state
        return (
            < div className="container-fluid" >
                <div className='row justify-content-center' style={{ minHeight: 250 }}>
                    <div className="col-2">
                        <TreeClient handleChange={this.handleChangeClient} />
                    </div>
                    <div className="col-auto " style={{ padding: 4 }}>
                        <FilterInput label='Ref' type='text' onChange={this.onChange} />
                    </div>
                    <div className="col-auto" style={{ padding: 4 }}>
                        <FilterInput label='Of' type='text' onChange={this.onChange} />
                    </div>
                    <div className="col-auto" style={{ padding: 4 }}>
                        <FilterInput label='Plateau' type='text' onChange={this.onChange} />
                    </div>
                    <div className="col-1" style={{ padding: 4 }}>
                        <FilterSelect label='Type' labelId='id_typ_prod' onChange={this.onChange} options={this.props.listeTypeProduit} />
                    </div>
                    <div className="col-1" style={{ padding: 4 }}>
                        <FilterSelect label='Stade' labelId='id_stade' onChange={this.onChange} options={this.props.listeStade} />
                    </div>
                    <div className="col-1" style={{ padding: 4 }}>
                        <FilterSelect label='Metal' labelId='id_type' onChange={this.onChange} options={this.props.listeMetaux} />
                    </div>


                    <div className="col-auto">
                        <button style={{ minHeight: 180 }} className='btn btn-primary btn-lg' onClick={this.afficher}>Afficher</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Doughnut data={doughnutData} options={options} getElementAtEvent={(elems) => {
                            if (elems.length > 0) {
                                const segmentIndex = elems[0]._index;
                                const segmentData = listeStockProduit[segmentIndex];
                                this.handleTrancheClick(segmentData);
                            }
                        }}
                        />
                    </div>
                    <div className="col-6">
                        <Doughnut data={doughnutDataPrixR} options={optionsPrixR} getElementAtEvent={(elems) => {
                            if (elems.length > 0) {
                                const segmentIndex = elems[0]._index;
                                const segmentData = listeStockProduit[segmentIndex];
                                this.handleTrancheClick(segmentData);
                            }
                        }}
                        />
                    </div>
                    <PopupTable
                        open={popupOpen}
                        handleClose={() => this.setState({ popupOpen: false })}
                        columns={columnsData}
                        title={title}
                        data={data}
                    />
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        listeTypeProduit: state.generalData.listeTypeProduit,
        listeMetaux: state.generalData.listeMetaux,
    };
};

const mapDispatchToProps = {
    getTypeProduit,
    getMetaux,
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyseStock);
