import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { setAuthentification } from "../actions/index";
import { Link, withRouter } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Form, Button } from 'react-bootstrap';
import requireUserAdmin from "../helpers/requireUserAdmin";

const Header = (props) => {
  const [openDropdown, setOpenDropdown] = useState(null);

  const logout = (e) => {
    e.preventDefault();
    props.setAuthentification(false);
    props.history.push("/");
    localStorage.removeItem('user');
  };

  const handleDropdownToggle = (dropdownId) => {
    setOpenDropdown(openDropdown === dropdownId ? null : dropdownId);
  };

  const handleCloseDropdown = () => {
    setOpenDropdown(null);
  };

  const AdminNavDropdown = requireUserAdmin(NavDropdown, 1);

  return (
    <Navbar collapseOnSelect bg="light" expand="xl" style={{ zIndex: 200 }}>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          {props.isLoggedIn && (
            <Fragment>
              <AdminNavDropdown
                title="Administration"
                id="nav-admin"
                show={openDropdown === 'admin'}
                onToggle={() => handleDropdownToggle('admin')}>
                <Link onClick={handleCloseDropdown} to="/cours" className="nav-link nav-child">Cours</Link>
                <Link onClick={handleCloseDropdown} to="/client" className="nav-link nav-child">Client</Link>
                <Link onClick={handleCloseDropdown} to="/svg" className="nav-link nav-child">SVG</Link>
              </AdminNavDropdown>

              <AdminNavDropdown
                title="Produit"
                id="nav-produit"
                show={openDropdown === 'produit'}
                onToggle={() => handleDropdownToggle('produit')}>
                <Link onClick={handleCloseDropdown} to="/produit" className="nav-link nav-child">Liste</Link>
                <Link onClick={handleCloseDropdown} to="/produit/cv" className="nav-link nav-child">Centre Vide</Link>
                <Link onClick={handleCloseDropdown} to="/produit/centre" className="nav-link nav-child">Centre</Link>
                <Link onClick={handleCloseDropdown} to="/produit/stock" className="nav-link nav-child">Stock</Link>
                <Link onClick={handleCloseDropdown} to="/reservation" className="nav-link nav-child">Reservation Collection</Link>
              </AdminNavDropdown>

              <AdminNavDropdown
                title="OF"
                id="nav-of"
                show={openDropdown === 'of'}
                onToggle={() => handleDropdownToggle('of')}>
                <Link onClick={handleCloseDropdown} to="/of/tracer" className="nav-link nav-child">Tracer OF</Link>
                <Link onClick={handleCloseDropdown} to="/of/edit" className="nav-link nav-child">Liste</Link>
                <Link onClick={handleCloseDropdown} to="/of" className="nav-link nav-child">Création</Link>
                <Link onClick={handleCloseDropdown} to="/planning" className="nav-link nav-child">Planning</Link>
                <Link onClick={handleCloseDropdown} to="/of/tools" className="nav-link nav-child">OF Tools</Link>
                <Link onClick={handleCloseDropdown} to="/of/besoin" className="nav-link nav-child">Edition de Besoin</Link>
              </AdminNavDropdown>

              <AdminNavDropdown
                title="FACTURATION"
                id="nav-facturation"
                show={openDropdown === 'facturation'}
                onToggle={() => handleDropdownToggle('facturation')}>
                <Link onClick={handleCloseDropdown} to="/livraion" className="nav-link nav-child">Facturer</Link>
                <Link onClick={handleCloseDropdown} to="/avoir" className="nav-link nav-child">Avoir</Link>
              </AdminNavDropdown>

              <AdminNavDropdown
                title="ANALYSE"
                id="nav-analyse"
                show={openDropdown === 'nav-analyse'}
                onToggle={() => handleDropdownToggle('nav-analyse')}>
                <Link onClick={handleCloseDropdown} to="/filter" className="nav-link nav-child">Suivi Commande</Link>
                <Link onClick={handleCloseDropdown} to="/analyse_ca" className="nav-link nav-child">Analyse CA</Link>
                <Link onClick={handleCloseDropdown} to="/suivi_metal" className="nav-link nav-child">Suivi Metal</Link>
                <Link onClick={handleCloseDropdown} to="/analyse_stock" className="nav-link nav-child">Analyse STOCK</Link>
                <Link onClick={handleCloseDropdown} to="/comparaison_ca" className="nav-link nav-child">Comparaison CA Client</Link>
                <Link onClick={handleCloseDropdown} to="/facon" className="nav-link nav-child">Analyse Facon</Link>
                <Link onClick={handleCloseDropdown} to="/analyse_fournisseur" className="nav-link nav-child">Analyse Fournisseur</Link>
                <Link onClick={handleCloseDropdown} to="/suivi_connection" className="nav-link nav-child">Connexions</Link>
              </AdminNavDropdown>

              <AdminNavDropdown
                title="Atelier"
                id="nav-atelier"
                show={openDropdown === 'atelier'}
                onToggle={() => handleDropdownToggle('atelier')}>
                <Link onClick={handleCloseDropdown} to="/finaliser" className="nav-link nav-child">Finaliser</Link>
                <Link onClick={handleCloseDropdown} to="/atelier/envoi" className="nav-link nav-child">Envoi / Retour Atelier</Link>
                <Link onClick={handleCloseDropdown} to="/serti/envoi" className="nav-link nav-child">Envoi / Retour Serti</Link>
                <Link onClick={handleCloseDropdown} to="/atelier/boite_or" className="nav-link nav-child">Boite Or</Link>
                <Link onClick={handleCloseDropdown} to="/atelier/analyse_ouvrier" className="nav-link nav-child">Analyse Atelier</Link>
                <Link onClick={handleCloseDropdown} to="/atelier" className="nav-link nav-child">Atelier</Link>
              </AdminNavDropdown>

              <AdminNavDropdown
                title="Pierre"
                id="nav-pierre"
                show={openDropdown === 'pierres'}
                onToggle={() => handleDropdownToggle('pierres')}>
                <Link onClick={handleCloseDropdown} to="/pierre" className="nav-link nav-child">Liste</Link>
                <Link onClick={handleCloseDropdown} to="/pierre/reception" className="nav-link nav-child">Reception</Link>
                <Link onClick={handleCloseDropdown} to="/pierre/sortie" className="nav-link nav-child">Alimenter</Link>
                <Link onClick={handleCloseDropdown} to="/pierre/retour" className="nav-link nav-child">Retourner</Link>
                <Link onClick={handleCloseDropdown} to="/pierre/stock" className="nav-link nav-child">Stock</Link>
              </AdminNavDropdown>

              <AdminNavDropdown
                title="Compta"
                id="nav-compta"
                show={openDropdown === 'compta'}
                onToggle={() => handleDropdownToggle('compta')}>
                <Link onClick={handleCloseDropdown} to="/compta" className="nav-link nav-child">Facture / Avoir</Link>
                <Link onClick={handleCloseDropdown} to="/valorisation" className="nav-link nav-child">Valorisation Stock</Link>
                <Link onClick={handleCloseDropdown} to="/traite" className="nav-link nav-child">Traite</Link>
                <Link onClick={handleCloseDropdown} to="/traite_fournisseur" className="nav-link nav-child">Traite Fournisseur</Link>
              </AdminNavDropdown>

              <AdminNavDropdown
                title="Image"
                id="nav-image"
                show={openDropdown === 'image'}
                onToggle={() => handleDropdownToggle('image')}>
                <Link onClick={handleCloseDropdown} to="/upload" className="nav-link nav-child">Upload</Link>
                <Link onClick={handleCloseDropdown} to="/image" className="nav-link nav-child">Gestion Image</Link>
                <Link onClick={handleCloseDropdown} to="/affectation" className="nav-link nav-child">Affectation Image</Link>
              </AdminNavDropdown>

              <AdminNavDropdown
                title="Impression"
                id="nav-impression"
                show={openDropdown === 'impression'}
                onToggle={() => handleDropdownToggle('impression')}>
                <Link
                  to="/"
                  onClick={() => window.open("https://extensia-france.com/printers/referencier.php", "_blank")}
                  className="nav-link nav-child">Referencier</Link>
                <Link
                  to="/"
                  onClick={() => window.open("https://www.extensia-france.com/printers/impression_ligne.php", "_blank")}
                  className="nav-link nav-child">Lignes</Link>
                <Link
                  to="/"
                  onClick={() => window.open("https://extensia-france.com/printers/tourDeDoigtPrinter.php", "_blank")}
                  className="nav-link nav-child">Alliance Doigt</Link>
                <Link
                  to="/"
                  onClick={() => window.open("https://extensia-france.com/printers/livre_police.php", "_blank")}
                  className="nav-link nav-child">Livre de Police</Link>
              </AdminNavDropdown>
            </Fragment>
          )}
        </Nav>
        <Form inline>
          <Button variant="link" onClick={logout} style={{ borderRadius: "20px", padding: "5px 15px", fontSize: "16px", textDecoration: "none", color: "#333" }}>
            Déconnexion
          </Button>
        </Form>
      </Navbar.Collapse>
    </Navbar>
  );
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.authentification.isLoggedIn,
});

const mapDispatchToProps = {
  setAuthentification,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
